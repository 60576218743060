export const selectLogo = (locale, isThemeDark) => {
  if (locale === 'en-gb' && !isThemeDark) {
    return 'light-english';
  }
  if (isThemeDark) {
    return 'dark';
  }
  return 'light';
};

export default {
  selectLogo,
};
